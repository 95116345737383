<template>
  <div class="view--configure-time-admin">
    <div class="back-button">
      <v-btn depressed color="primary" dark @click="goBack()">
        <v-icon>mdi-arrow-left</v-icon>
        Volver
      </v-btn>
    </div>
    <div class="grid-container">
      <div class="left-side">
        <header class="configure-time-admin--header">
          <div class="configure-time-admin--header__title" v-if="worker">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  color="primary"
                  dark
                  icon
                  @click="goToWorkerPanel()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-account-cog</v-icon>
                </v-btn>
              </template>
              <span>Ir a panel de Trabajador</span>
            </v-tooltip>

            <div
              class="configure-time-admin--header__title__text"
              v-if="worker"
            >
              <h2>{{ worker.name + " " + worker.lastName1 }}</h2>
              <div>{{ worker.email }}</div>
            </div>

          </div>
          <div
            v-if="this.itemData.eventTime"
            class="configure-time-admin--header__date"
          >
            <span> {{ this.formatDate(this.itemData.eventTime) }}</span>

            <v-btn
              depressed
              color="primary"
              dark
              @click="changeDayBlockedStatus()"
              ><span v-if="!isBlocked"
                ><v-icon left dark small>mdi-lock</v-icon>Bloquear día
              </span>
              <span v-else
                ><v-icon left dark small>mdi-lock-open</v-icon>Desbloquear día
              </span></v-btn
            >
          </div>
          <div
            v-else
            class="configure-time-admin--header__date"
          >
            <span>Selecciona un día</span>

          </div>
        </header>
        <mdtec-panel>
          <template v-slot:title v-if="worker"
            >Cuadrante de {{ worker.name + " " + worker.lastName1 }}</template
          >
          <template v-slot:content>
            <WorkerCalendar
              ref="workerCalendar"
              :workerId="worker.id"
              :isEditable="true"
              v-if="worker"
              :v-show="!loading"
              @focus="updateDate"
            />
          </template>
        </mdtec-panel>
      </div>
      <div class="right-side">
        <div class="panel-loader" v-if="loading">
          <v-progress-circular
            indeterminate
            :color="$getColor('vuetifyColor1')"
          ></v-progress-circular>
        </div>
        <div class="check-buttons" v-show="!loading">
          <div class="option-selector--container field-group">
            <label class="option-selector--label field-group--label"
              >Tipo de turno <span class='blocked-text' v-if="isBlocked">(Día bloqueado)</span></label
            >
            <v-tooltip bottom v-if="!isBlocked">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  color="primary"
                  class="mb-2 ml-2"
                  dark
                  x-large
                  icon
                  @click="selectBlank()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-refresh-circle</v-icon>
                </v-btn>
              </template>
              <span>Reiniciar día</span>
            </v-tooltip>
            <div class="check-buttons--list">
              <check-selector
                v-show="!loading"
                title="Turno de mañana"
                subtitle=""
                ref="morningSelector"
                v-on:click.native="selectMorning"
                color="blue"
                :disabled="isBlocked"
              />
              <check-selector
                v-show="!loading"
                title="Turno de tarde"
                subtitle=""
                ref="eveningSelector"
                v-on:click.native="selectEvening"
                color="yellow"
                :disabled="isBlocked"
              />
              <check-selector
                v-show="!loading"
                title="Turno de noche"
                subtitle=""
                ref="nightSelector"
                v-on:click.native="selectNight"
                color="red"
                :disabled="isBlocked"
              />
              <check-selector
                v-show="!loading"
                title="Otro"
                subtitle=""
                ref="otherSelector"
                v-on:click.native="selectOther"
                color="purple"
                :disabled="isBlocked"
              />
              <check-selector
                v-show="!loading"
                title="Ausencia/Baja"
                subtitle=""
                ref="absenceSelector"
                v-on:click.native="selectAbsence"
                color="green"
                :disabled="isBlocked"
              />
              <check-selector v-show="!loading" title="Guardia" subtitle="" ref="guardSelector"
              v-on:click.native="selectGuard" color="orange" :disabled="isBlocked" />
            </div>

          </div>
        </div>

        <div class="codes-containter">
          <div
            class="option-selector--container field-group"
            v-if="
              optionsSelected &&
              optionsSelected.type &&
              optionsSelected.type !== 'ABSENCE' &&
              optionsSelected.type !== 'OTHER' &&
              optionsSelected.type !== 'GUARD' &&
              optionsSelected.type !== 'BLANK'
            "
          >
            <label class="option-selector--label field-group--label"
              >Códigos de Administración <span class='blocked-text' v-if="isBlocked">(Día bloqueado)</span></label
            >
            <div class="field-grid">
              <selection-card
                v-for="item in worker.n2Codes"
                v-show="item.autoVariable"
                :key="item.id"
                :name="item.name"
                class="swiper-slide customGreen"
                :disableMouse="true"
                size="large"
                :description="item.code"
                selected
                :disable-mouse="isBlocked"
              />
            </div>
          </div>
          <div
            class="option-selector--container field-group"
            v-if="
              optionsSelected &&
              optionsSelected.codes &&
              optionsSelected.codes.length > 0
            "
          >
            <label class="option-selector--label field-group--label" for=""
              >Lista de códigos <span class='blocked-text' v-if="isBlocked">(Día bloqueado)</span></label
            >
            <div class="field-grid">
              <selection-card
                v-for="item in optionsSelected.codes"
                v-show="!item.extraHour && !item.autoVariable"
                :key="item.id"
                :name="item.name"
                :class="
                  !item.admin
                    ? 'swiper-slide customRed'
                    : 'swiper-slide customPurple'
                "
                size="large"
                :description="item.code"
                v-on:click.native="setValue(item)"
                selected
                :disable-mouse="isBlocked"
              />
            </div>
          </div>
          <div
            class="option-selector--container field-group"
            v-if="
              optionsSelected &&
              optionsSelected.codes &&
              optionsSelectedWithExtraHours.codes.length > 0
            "
          >
            <label class="option-selector--label field-group--label" for=""
              >Códigos de unidades <span class='blocked-text' v-if="isBlocked">(Día bloqueado)</span></label
            >
            <div class="field-grid dir-column">
              <div
                class="field-grid--row"
                v-for="(item, key) in optionsSelectedWithExtraHours.codes"
                :key="item.id"
              >
                <selection-card
                  :key="item.id"
                  :name="item.name"
                  class="swiper-slide customRed"
                  size="large"
                  :description="item.code"
                  v-on:click.native="setValue(item)"
                  selected
                  :disable-mouse="isBlocked"
                />
                <v-text-field
                  v-model="optionsSelected.extraHours[key]"
                  label="Nº de unidades"
                  type="number"
                  min="0"
                  max="24"
                  step="0.5"
                  outlined
                  hide-details
                  :disabled="isBlocked"
                  class="min-width-input"
                ></v-text-field>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      :dark="$getPrimaryButton('dark')"
                      :rounded="$getPrimaryButton('rounded')"
                      :outlined="$getPrimaryButton('outlined')"
                      :color="$getPrimaryButton('color')"
                      v-bind="attrs"
                      v-on="on"
                      v-if="!isBlocked"
                    >
                      <v-icon left small>check_circle</v-icon> GUARDAR
                    </v-btn>
                  </template>
                  <span>Guardar unidades</span>
                </v-tooltip>
              </div>
            </div>
          </div>
          <div
            class="option-selector--container field-group"
            v-if="options && optionsRecommended.length > 0 && !isBlocked"
          >
            <label class="option-selector--label field-group--label" for=""
              >Códigos sugeridos</label
            >
            <div class="field-grid">
              <selection-card
                v-for="item in optionsRecommended"
                :key="item.id"
                :name="item.name"
                class="swiper-slide"
                size="large"
                :description="item.code"
                v-on:click.native="setValue(item)"
                :selected="checkValue(item.id)"
                :disable-mouse="isBlocked || updatingCodes"
              />
            </div>
          </div>
          <div
            class="option-selector--container field-group"
            v-if="optionsExtra.length > 0 && !isBlocked"
          >
            <label class="option-selector--label field-group--label" for=""
              >Añade más códigos</label
            >
            <div class="field-grid">
              <selection-card
                v-for="item in optionsExtra"
                :key="item.id"
                :name="item.name"
                class="swiper-slide"
                size="large"
                :description="item.code"
                v-on:click.native="setValue(item)"
                :selected="checkValue(item.id)"
                :disable-mouse="isBlocked || updatingCodes"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authService from '@/services/auth.service';

import CheckSelector from '@/components/Buttons/CheckSelector.vue';
import SelectionCard from '@/components/Cards/CodeCard.vue';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import ConfigureTime from '@/services/configure-time.service';
import scheduleService from '@/services/schedule.service';
import workcenterService from '@/services/workcenter.service';

import WorkerCalendar from '@/views/_FATDA/components/WorkerCalendar.vue';
import MdtecPanel from '@/components/Cards/MdtecPanel.vue';

export default {
  name: 'configure-time',
  mixins: [validationMixin],
  components: {
    CheckSelector,
    SelectionCard,
    WorkerCalendar,
    MdtecPanel,
  },

  data: () => ({
    isAdmin: false,
    user: null,
    itemData: {
      type: null,
      eventTime: null,
    },
    // minDate = first day of current month
    minDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      2,
    ).toISOString(),
    // maxDate = last day of the current month
    maxDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      1,
    ).toISOString(),
    // currentMonth
    currentMonth: new Date().getMonth(),

    modal: false,
    loading: false,
    updatingCodes: false,

    options: null,
    selectedDate: null,
    worker: null,
    n2current: [],

  }),
  mounted() {
    this.user = authService.getDecodedJwtToken(this.$store.state.auth.user);
    this.isAdmin = this.$store.getters['auth/hasRole']([
      'ROLE_SUPER_ADMIN',
      'ROLE_ADMIN',
      'ROLE_ENCARGADO',
    ]);
    this.getWorker();
  },
  computed: {
    isBlocked: {
      get() {
        const tempSelected = this.n2current[
          this.n2current.findIndex((item) => item.date === this.selectedDate)
        ];
        if (tempSelected) {
          return tempSelected.blocked;
        }
        return null;
      },
      set() {
        const tempSelected = this.n2current[
          this.n2current.findIndex((item) => item.date === this.selectedDate)
        ];
        if (tempSelected) {
          tempSelected.blocked = !tempSelected.blocked;
        }
      },
    },
    optionsSelected() {
      const tempSelected = this.n2current[
        this.n2current.findIndex((item) => item.date === this.selectedDate)
      ];
      // order by code.id
      if (tempSelected) {
        tempSelected.codes.sort((a, b) => a.id - b.id);
      }
      return tempSelected;
    },
    optionsSelectedWithExtraHours() {
      if (!this.optionsSelected) {
        return null;
      }
      // create a copy of optionsSelected to avoid modifying the original object
      const optionsSelectedCopy = JSON.parse(
        JSON.stringify(this.optionsSelected),
      );
      // return items in optionsSelected with extraHours equal to true
      optionsSelectedCopy.codes = optionsSelectedCopy.codes.filter(
        (item) => item.extraHour,
      );
      optionsSelectedCopy.codes.sort((a, b) => a.id - b.id);
      return optionsSelectedCopy;
    },
    optionsRecommended() {
      let newOptions = [];
      if (!this.optionsSelected || !this.options) {
        return newOptions;
      }
      if (this.optionsSelected.type === 'ABSENCE') {
        newOptions = this.options;
        return newOptions;
      }
      if (this.optionsSelected.type === 'NIGHT') {
        newOptions = this.options.filter(
          (item) => item.type === 'all' || item.type === 'night',
        );
      } else if (
        this.optionsSelected.type === 'EVENING'
        || this.optionsSelected.type === 'MORNING'
      ) {
        newOptions = this.options.filter(
          (item) => item.type === 'all' || item.type === 'morning/evening',
        );
      } else if (this.optionsSelected.type === 'OTHER' || this.optionsSelected.type === 'GUARD') {
        // newOptions = this.options.filter((item) => item.extraHour === false);
        // filter this.options
        newOptions = this.options.filter((item) => item.code === '2007' || item.code === '2095' || item.code === '2002'
        || item.code === '2017' || item.code === '2386' || item.code === '2343');
      }
      const isWeekend = ConfigureTime.isWeekend(this.itemData.eventTime);
      // if isWeekend true, exclude all items with weekend: false
      if (isWeekend) {
        newOptions = newOptions.filter((item) => item.weekend !== '0');
      } else {
        newOptions = newOptions.filter((item) => item.weekend !== '1');
      }

      // exclude items with autoVariation: true
      newOptions = newOptions.filter((item) => item.autoVariable !== true);

      return newOptions;
    },
    optionsExtra() {
      const newOptions = [];
      if (!this.optionsSelected || !this.options) {
        return newOptions;
      }
      this.options.forEach((item) => {
        if (this.optionsRecommended.indexOf(item) === -1) {
          // if item.autoVariable is true, add it to the list
          if (!item.autoVariable) {
            newOptions.push(item);
          }
        }
      });
      // if optionsSelected.type is other, remove all items with extraHour: true
      if (this.optionsSelected.type === 'OTHER' || this.optionsSelected.type === 'GUARD') {
        // return newOptions.filter((item) => item.extraHour === false);
        // return newOptions.filter((item) => item.code === '2007' || item.code === '2095' || item.code === '2002');
        return newOptions;
      }
      return newOptions;
    },

  },

  watch: {
    // watch changes on itemData.eventTime
    'itemData.eventTime': {
      handler() {
        this.getCurrentN2();
      },
      deep: true,
    },
    // watch changes on itemData.type
    'itemData.type': {
      handler() {
        this.updateData();
      },
      deep: true,
    },
    n2current: {
      handler() {
        this.updateCodes();
      },
      deep: true,
    },
  },
  validations() {
    const valObj = {
      itemData: {
        type: { required },
        eventTime: { required },
        totalOnSelectedDate: {
          // check if totalOnSelectedDate is less than 10
          lessThan: (val) => val < 6,
        },
      },
    };
    return valObj;
  },
  methods: {
    getWorker() {
      workcenterService.getWorker(this.$route.params.id).then((response) => {
        this.worker = response;
        this.getSchedule();
      });
    },
    formatDate(date) {
      if (date) {
        return ConfigureTime.formatDate(date);
      }
      return null;
    },
    goToWorkerPanel() {
      this.$router.push({
        name: 'WorkerPanel',
        params: { id: this.worker.id },
      });
    },
    updateDate(date) {
      if (!this.loading) {
        const newMonth = new Date(date).getMonth();
        const newYear = new Date(date).getFullYear();
        if (newMonth !== this.currentMonth) {
          this.currentMonth = newMonth;
          this.minDate = new Date(
            newYear,
            newMonth,
            2,
          ).toISOString();
          this.maxDate = new Date(
            newYear,
            newMonth + 1,
            1,
          ).toISOString();
          this.resetData();
          this.getSchedule();
        } else {
          this.itemData.eventTime = date;
        }
      }
    },

    changeDayBlockedStatus() {
      this.loading = true;
      scheduleService
        .changeDayBlockedStatus(this.optionsSelected.id)
        .then(() => {
          this.loading = false;
          this.isBlocked = !this.isBlocked;
          this.$refs.workerCalendar.updateData();
        })
        .catch(() => {
          this.loading = false;
        });
    },

    getSchedule() {
      this.loading = true;
      const dateFrom = new Date(this.minDate).toISOString().split('T')[0];
      const dateTo = new Date(this.maxDate).toISOString().split('T')[0];
      scheduleService
        .getDaysInRange(this.worker.id, dateFrom, dateTo)
        .then((response) => {
          this.n2current = response;
          this.loading = false;
          this.$refs.workerCalendar.updateData();
        });
    },

    getCurrentN2() {
      this.selectedDate = this.itemData.eventTime;
      this.resetData();
      this.checkAvailability();
      this.n2 = this.n2current.filter(
        (item) => item.date === this.itemData.eventTime,
      );
      if (this.n2.length > 0) {
        if (this.optionsSelected && this.optionsSelected.type) {
          this.selectData(this.optionsSelected.type);
        }
      } else {
        // add new item to n2current
        this.loading = true;
        scheduleService
          .addDayToWorkerSchedule(this.worker.id, {
            date: this.itemData.eventTime,
            type: null,
            codes: [],
          })
          .then(() => {
            this.getSchedule();
            // this.$refs.workerCalendar.updateData();
          });
      }
    },

    updateCodes() {
      if (
        !this.loading
        && !this.updatingCodes
        && this.optionsSelected
        && this.optionsSelected.id
        && this.isAdmin && !this.isBlocked
      ) {
        this.updatingCodes = true;
        scheduleService
          .updateDay(this.optionsSelected.id, this.optionsSelected)
          .then(() => {
            this.$refs.workerCalendar.updateData();
            this.updatingCodes = false;
          });
      }
    },

    setValue(item) {
      // check if item is already selected
      if (this.checkValue(item.id)) {
        if (item.extraHour) {
          this.updateRemoveOptionsSelectedExtraHours(item);
        }
        // remove item from optionsSelected
        this.optionsSelected.codes.splice(
          this.optionsSelected.codes.findIndex((i) => i.id === item.id),
          1,
        );
        // add item to options
        this.options.push(item);
      } else {
        // add item to optionsSelected
        this.optionsSelected.codes.push(item);
        // remove item from options
        this.options = this.options.filter((i) => i.id !== item.id);
        // check if item is extrahours
        if (item.extraHour) {
          this.updateOptionsSelectedExtraHours(item);
        }
      }
    },
    updateOptionsSelectedExtraHours(item) {
      // check if itemSelectedWithExtraHours has already items
      if (this.optionsSelectedWithExtraHours.codes.length > 0) {
        // optionsSelectedWithExtraHours is an array ordered by id
        // check the position of the new item
        const index = this.optionsSelectedWithExtraHours.codes.findIndex(
          (i) => i.id === item.id,
        );
        // add new item to the optionsSelected.extraHours[key]
        if (index === -1) {
          // add new item to the end of the array with value 0
          this.optionsSelected.extraHours.push(0);
        } else {
          // add new item to the position of the array with value 0
          this.optionsSelected.extraHours.splice(index, 0, 0);
        }
      }
    },
    updateRemoveOptionsSelectedExtraHours(item) {
      // check if itemSelectedWithExtraHours has already items
      if (this.optionsSelectedWithExtraHours.codes.length > 0) {
        // optionsSelectedWithExtraHours is an array ordered by id
        // check the position of the item
        const index = this.optionsSelectedWithExtraHours.codes.findIndex(
          (i) => i.id === item.id,
        );
        // remove item from the optionsSelected.extraHours[key]
        if (index !== -1) {
          this.optionsSelected.extraHours.splice(index, 1);
        }
      }
    },
    checkValue(id) {
      // check if item is already selected
      return !!this.optionsSelected.codes.find((item) => item.id === id);
    },
    selectData(type) {
      if (type === 'MORNING') {
        this.selectMorning();
      } else if (type === 'EVENING') {
        this.selectEvening();
      } else if (type === 'ABSENCE') {
        this.selectAbsence();
      } else if (type === 'NIGHT') {
        this.selectNight();
      } else if (type === 'OTHER') {
        this.selectOther();
      } else if (type === 'GUARD') {
        this.selectGuard();
      }
    },
    // check availability
    checkAvailability() {
      this.loading = true;
      this.$refs.morningSelector.updateEnabled(true);
      this.$refs.eveningSelector.updateEnabled(true);
      this.$refs.nightSelector.updateEnabled(true);
      this.$refs.absenceSelector.updateEnabled(true);
      this.$refs.otherSelector.updateEnabled(true);
      this.$refs.guardSelector.updateEnabled(true);
      this.loading = false;
    },
    updateData() {
      if (this.itemData && this.itemData.type) {
        this.optionsSelected.type = this.itemData.type;
      }
      if (this.itemData.type === 'ABSENCE') {
        this.allOptions = ConfigureTime.getAbsencesOptions(this.worker.n2Codes);
        // filter optionsSelected.codes to remove all items that are not in allOptions
        this.optionsSelected.codes = this.optionsSelected.codes.filter((item) => this.allOptions.find((i) => i.id === item.id));
        // filter options to remove already selected items
        this.options = this.allOptions.filter(
          (item) => !this.checkValue(item.id),
        );
      } else if (
        this.itemData.type === 'MORNING'
        || this.itemData.type === 'EVENING'
        || this.itemData.type === 'NIGHT'
        || this.itemData.type === 'OTHER'
        || this.itemData.type === 'GUARD'
      ) {
        this.allOptions = ConfigureTime.getVariablesOptions(
          this.worker.n2Codes,
        );
        // filter optionsSelected.codes to remove all items that are not in allOptions
        this.optionsSelected.codes = this.optionsSelected.codes.filter((item) => this.allOptions.find((i) => i.id === item.id));
        // filter options to remove already selected items
        this.options = this.allOptions.filter(
          (item) => !this.checkValue(item.id),
        );
      } else {
        this.allOptions = [];
      }
    },

    resetData() {
      this.options = null;
      this.itemData.type = null;
      this.$refs.morningSelector.updateSelected(false);
      this.$refs.eveningSelector.updateSelected(false);
      this.$refs.nightSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(false);
      this.$refs.morningSelector.updateEnabled(false);
      this.$refs.eveningSelector.updateEnabled(false);
      this.$refs.nightSelector.updateEnabled(false);
      this.$refs.absenceSelector.updateEnabled(false);
      this.$refs.otherSelector.updateEnabled(false);
      this.$refs.otherSelector.updateSelected(false);

      this.$refs.guardSelector.updateSelected(false);
      this.$refs.guardSelector.updateEnabled(false);
    },

    selectMorning() {
      this.itemData.type = 'MORNING';
      this.$refs.morningSelector.updateSelected(true);
      this.$refs.eveningSelector.updateSelected(false);
      this.$refs.nightSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(false);
      this.$refs.otherSelector.updateSelected(false);

      this.$refs.guardSelector.updateSelected(false);
    },
    selectEvening() {
      this.itemData.type = 'EVENING';
      this.$refs.eveningSelector.updateSelected(true);
      this.$refs.morningSelector.updateSelected(false);
      this.$refs.nightSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(false);
      this.$refs.otherSelector.updateSelected(false);

      this.$refs.guardSelector.updateSelected(false);
    },
    selectNight() {
      this.itemData.type = 'NIGHT';
      this.$refs.nightSelector.updateSelected(true);
      this.$refs.eveningSelector.updateSelected(false);
      this.$refs.morningSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(false);
      this.$refs.otherSelector.updateSelected(false);

      this.$refs.guardSelector.updateSelected(false);
    },
    selectAbsence() {
      this.itemData.type = 'ABSENCE';
      this.$refs.eveningSelector.updateSelected(false);
      this.$refs.morningSelector.updateSelected(false);
      this.$refs.nightSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(true);
      this.$refs.otherSelector.updateSelected(false);

      this.$refs.guardSelector.updateSelected(false);
    },
    selectOther() {
      this.itemData.type = 'OTHER';
      this.$refs.eveningSelector.updateSelected(false);
      this.$refs.morningSelector.updateSelected(false);
      this.$refs.nightSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(false);
      this.$refs.otherSelector.updateSelected(true);

      this.$refs.guardSelector.updateSelected(false);
    },
    selectGuard() {
      this.itemData.type = 'GUARD';
      this.$refs.eveningSelector.updateSelected(false);
      this.$refs.morningSelector.updateSelected(false);
      this.$refs.nightSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(false);
      this.$refs.otherSelector.updateSelected(false);

      this.$refs.guardSelector.updateSelected(true);
    },

    selectBlank() {
      this.itemData.type = 'BLANK';
      this.$refs.eveningSelector.updateSelected(false);
      this.$refs.morningSelector.updateSelected(false);
      this.$refs.nightSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(false);
      this.$refs.otherSelector.updateSelected(false);

      this.$refs.guardSelector.updateSelected(false);
      this.allOptions = [];
      this.options = null;
      this.optionsSelected.codes = [];
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.v-picker--date {
  // margin-bottom: calc(var(--base) * 4);
  z-index: 0;
}

// ::v-deep .theme--light.v-btn.v-btn--disabled,
// .theme--light.v-btn.v-btn--disabled .v-btn__loading,
// .theme--light.v-btn.v-btn--disabled .v-icon {
//   background-color: #f1f1f1;
// }
.page-layout {
  height: 100%;
}

/*add selected with vuetifyColor*/
.selected {
  border: 2px solid var(--color1);
}

// .option-selector--container {
//   // margin-bottom: calc(var(--base) * 2);
//   // margin-top: calc(var(--base) * 3);
// }
.codes-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: calc(var(--base) * 2);
  overflow-x: hidden;
  grid-column: 1 / span 5;
  width: calc(100% + calc(var(--base) * 6));
  margin-left: calc(var(--base) * -3);
}

//redisign
.view--configure-time-admin {
  display: flex;
  flex-direction: column;

  .grid-container {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: calc(var(--base) * 4);

    @media (min-width: 1024px) {
      grid-template-columns: repeat(12, 1fr);
      padding-top: 0;
    }
  }

  .left-side {
    grid-column: 1 / span 6;
    height: 100%;
    display: flex;
    flex-direction: column;

    // padding-bottom: calc(var(--base) * 4);
    @media (min-width: 1024px) {
      grid-column: 1 / span 7;
    }

    .configure-time-admin--header {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: calc(var(--base) * 1);

      &__title {
        display: flex;
        gap: calc(var(--base) * 1);
        justify-content: space-between;
        align-items: center;

        &__text {
          font-size: calc(var(--base) * 2.5);
          line-height: calc(var(--base) * 3);
          font-weight: 300;
          display: flex;
          flex-wrap: wrap;
          gap: calc(var(--base) * 0.5);
          margin-right: auto;

          h2 {
            font-size: calc(var(--base) * 2.5);
            line-height: calc(var(--base) * 3);
            font-weight: 300;

            &::after {
              content: " - ";
            }
          }
        }
      }

      &__date {
        font-size: calc(var(--base) * 3);
        color: #161616;
        display: flex;
        align-content: center;
        justify-content: space-between;
        gap: calc(var(--base) * 1);
        padding: calc(var(--base) * 1) 0;
        margin-bottom: calc(var(--base) * 1);

        & > div {
          display: flex;
        }

        &__navigation {
          display: flex;
          gap: calc(var(--base) * 1);
        }
      }
    }
  }

  .right-side {
    grid-column: 1 / span 6;
    height: 100%;

    @media (min-width: 1024px) {
      grid-column: 8 / span 5;
    }
    .right-side--inner {
      pointer-events: none;
    }
    .check-buttons {
      display: flex;
      flex-direction: column;

      .check-buttons--list {
        margin-top: calc(var(--base) * 1);
        display: flex;
        flex-wrap: wrap;
        gap: calc(var(--base) * 2);
      }
    }

    .panel-loader {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
  .blocked-text{
    font-size: calc(var(--base) * 2);
    opacity: 0.6;
  }
}
</style>
<style lang="scss">
.view--configure-time-admin {
  & .v-picker__body.theme--light {
    margin: 0;
    width: 100% !important;
    padding: calc(var(--base) * 1) calc(var(--base) * 2);
  }

  & .v-date-picker-table .v-btn {
    font-size: calc(var(--base) * 2);
  }

  // & .v-picker__body.theme--light{
  //   height:100%;

  // }
  & .v-picker.v-card.v-picker--date.theme--light {
    height: 100%;

    & table {
      height: 100%;
    }

    // & tbody{
    //   height:100%;
    // }
  }

  & .v-picker__body.theme--light {
    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  & .v-date-picker-table.v-date-picker-table--date.theme--light {
    height: 100%;
  }

  & .accent--text {
    button {
      font-size: calc(var(--base) * 2.5);
      text-transform: capitalize;
    }
  }

  & .v-date-picker-header.theme--light {
    margin-top: calc(var(--base) * 1);
    margin-bottom: calc(var(--base) * 2);
  }

  & .v-date-picker-title {
    color: #161616;
    text-transform: capitalize;
  }

  & .v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
    background-color: #f4796b !important;

    & .v-btn__content {
      color: #161616;
    }
  }

  //Calendar
  & .mdtec-card {
    height: 100%;

    & .mdtec-card--content {
      height: 100%;

      & .admin--worker-calendar {
        height: 100%;
        //TO-DO: fix this
        max-height: calc(100vh - 350px);

        & .calendar {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }

    & .theme--light.v-calendar-weekly .v-calendar-weekly__day {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    & .v-event.v-event-start.v-event-end.white--text {
      width: 98% !important;

      & .event-chip {
        color: #161616 !important;
        padding: 0 calc(var(--base) * 0.5);
        font-weight: 400;
        justify-content: flex-start;
      }
    }
  }
}

.min-width-input{
  min-width: 120px;
}
</style>
