// add constant with color properties
const Options = {

  colors: {
    color1: '#F4796B',
    color2: '#ffc04a',
    color3: '#FF6E40',
    color4: '#FFFF8D',

    // vuetifyColor1: 'orange darken-1',
    vuetifyColor1: '#F4796B',
    // vuetifyColor2: 'orange lighten-1',
    vuetifyColor2: '#00bfa5',
    vuetifyColor3: '#ff4c4c',
    vuetifyColor4: '#ff6666',
    vuetifyColor5: '#ff7f7f',

    vuetifyAppBar: 'white',
    vuetifyNavSecondary: 'grey lighten-4',
    vuetifyColorAvatar: 'orange darken-1',

  },

  primaryButton: {
    color: '#F4796B',
    textColor: 'white',
    outlined: false,
    dark: true,
    rounded: false,
  },
  secondaryButton: {
    color: '#ffc04a',
    textColor: 'white',
    outlined: false,
    dark: true,
    rounded: false,
  },
  cards: {
    color1: '#FB8C00',
    color2: '#ffc04a',
    elevation: 0,
  },

  values: {
    appointmentType: {
      MORNING: {
        label: 'Mañana',
        longLabel: 'Turno de Mañana',
        color: '#00B0FF',
        icon: 'mdi-weather-sunny',
        dark: false,
      },
      EVENING: {
        label: 'Tarde',
        longLabel: 'Turno de Tarde',
        color: '#FFEB3B',
        icon: 'mdi-weather-sunny',
        dark: false,
      },
      NIGHT: {
        label: 'Noche',
        longLabel: 'Turno de Noche',
        color: '#FF4081',
        icon: 'mdi-weather-night',
        dark: false,
      },
      ABSENCE: {
        label: 'Ausencia',
        longLabel: 'Ausencia/Baja',
        color: '#03fc73',
        icon: 'mdi-account-remove',
        dark: false,
      },
      OTHER: {
        label: 'Otro',
        longLabel: 'Otro',
        color: '#bd45d1',
        icon: 'mdi-weather-text',
        dark: true,
      },
      GUARD: {
        label: 'Guardia',
        longLabel: 'Guardia',
        color: '#FF9800',
        icon: 'mdi-weather-text',
        dark: true,
      },
      BLANK: {
        label: '',
        longLabel: '*Sin completar*',
        color: '#FFFFFF',
        icon: 'mdi-weather-text',
        dark: true,
      },
    },
    clockEntryType: {
      CLOCK_IN: {
        label: 'Entrada',
        longLabel: 'Fichaje de entrada',
        color: '#f4796b',
        colorChip: '#212121',
        icon: 'mdi-clock-start',
        dark: false,
      },
      CLOCK_OUT: {
        label: 'Salida',
        longLabel: 'Fichaje de salida',
        color: '#BDBDBD',
        colorChip: '#212121',
        icon: 'mdi-clock-end',
        dark: false,
      },
    },
    roles: {
      ROLE_TRABAJADOR: {
        label: 'Trabajador',
        value: 0,
        color: '#ff6347',
      },
      ROLE_ENCARGADO: {
        label: 'Encargado',
        value: 1,
        color: '#f4796b',
      },
      ROLE_ADMIN: {
        label: 'Administrador',
        value: 2,
        color: '#00bfa5',
      },
      ROLE_SUPER_ADMIN: {
        label: 'Super Administrador',
        value: 3,
        color: '#ff1347',
      },
    },
    status: {
      Pendiente: {
        label: 'Pending',
        color: '#FB8C00',
        icon: 'alarm',
      },
      Aprobada: {
        label: 'Approved',
        color: '#00E676',
        icon: 'check',
      },
      Confirmado: {
        label: 'Confirmed',
        color: '#00E676',
        icon: 'check',
      },
      Cancelada: {
        label: 'Canceled',
        color: '#ff1347',
        icon: 'close',
      },

    },
    appointmentPropsType: {
      Photocall: {
        label: 'Photocall',
        color: '#ff6347',
        icon: 'work',
      },
      Neon: {
        label: 'Neon',
        color: '#ffc04a',
        icon: 'event',
      },
      Album: {
        label: 'Album',
        color: '#ffc04a',
        icon: 'event',
      },
      Plantilla: {
        label: 'Plantilla',
        color: '#ff4717',
        icon: 'photo',
      },
      Extras: {
        label: 'Extras',
        color: '#ff1347',
        icon: 'other_houses',
      },
    },

  },

};
// eslint-disable-next-line func-names
Options.install = function (Vue) {
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getColor = (key) => Options.colors[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getPrimaryButton = (key) => Options.primaryButton[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getSecondaryButton = (key) => Options.secondaryButton[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getValues = (key) => Options.values[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getInscriptionTypeValues = (key) => Options.values.inscriptionType[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getRoleTypeValues = (key) => Options.values.roles[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getGenderValues = (key) => Options.values.gender[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getEventTypeValues = (key) => Options.values.eventType[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getAppointmentTypeValues = (key) => Options.values.appointmentType[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getclockEntryTypeValues = (key) => Options.values.clockEntryType[key];

  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getProjectTypeValues = (key) => Options.values.projectType[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getStatusValues = (key) => Options.values.status[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getFeesOptions = (key) => Options.values.feesOptions[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getCardOptions = (key) => Options.cards[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getAppointmentPropsTypeValues = (key) => Options.values.appointmentPropsType[key];
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$getProjectCategory = (key) => Options.projectCategory[key];
};

export default Options;
